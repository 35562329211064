.loading {
    width: 150px;
    height: 150px;

    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    z-index: 750;


    opacity: .8;
   
}

.loading path {
    fill: var(--color-green);
}

@media(max-width:768px) {
    .loading {
        width: 70px;
        height: 70px;    
    }
}