.opener {
  position: relative;
  height: 100vh;
  min-height: 750px;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  overflow: hidden;
  background: #000;
}

.opener.no-image {
  height: auto;
  min-height: auto;
}

.opener .meta {
  position: absolute;
  bottom: 0;
  transform: translate(-50%, -25%);
  width: 100%;
  left: 50%;
  z-index: 50;
  max-width: 1400px;
  margin: 0 auto 30px;
  padding: 0 120px;
  box-sizing: border-box;
}

.opener .title {
  font-size: 44px;
  color: #fff;
  font-weight: 600;
  line-height: 1.2;
}

.opener .text {
  font-size: 40px;
  color: #fff;
  /* font-family: var(--font-primary); */
  font-weight: 300;
  line-height: 1.1;
}

.opener .text p {
  margin: 0;
}

.opener img {
  object-fit: cover;
  width: 100%;
  min-height: 100vh;
}

.opener:after {
  content: "";
  position: absolute;
  height: 50vh;
  width: 100%;
  z-index: 45;
  bottom: 0;
  left: 0;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.75) 25%,
    rgba(0, 0, 0, 1) 100%
  );
}

.opener:before {
  content: "";
  position: absolute;
  height: 1px;
  width: 100%;
  z-index: 45;
  bottom: 0;
  left: 0;
  background-color: #000;
}

/* Cinema Breakpoint */
@media (min-width: 1800px) {
  .opener .title {
    font-size: 53px;
  }
  .opener .text {
    font-size: 50px;
    padding: 0;
  }
}

/* Tablet Breakpoint */
@media (max-width: 1024px) {
  .opener .meta {
    padding: 0 50px;
  }
}

/* Phone Breakpoint */
@media (max-width: 768px) {
  .opener {
    min-height: 100vh;
  }

  .opener:after {
    min-height: 100vh;
    background: linear-gradient(
      to bottom,
      rgba(6, 130, 107, 0) 0%,
      rgba(6, 130, 107, 0.75) 75%,
      rgba(6, 130, 107, 1) 100%
    );
  }

  .opener .meta {
    padding: 80vh 30px 0;
    transform: translate(-50%, 0%);
  }

  .opener .title {
    font-size: 30px;
  }

  .opener .text {
    font-size: 24px;
  }

  .opener img.media {
    height: 100vh;
    transform: translateY(0);
  }
}
