.faq-block {
    margin-bottom: 40px;
    display: block;
    width: 100%;
    overflow: hidden;
    border-radius: 20px;
  }

  .faq-block a {
    font-weight: 900;
    color: var(--color-green);
    text-decoration: underline;
}

  .faq-page h1 ,
  .faq-page h2 {
    font-weight: 900;
    margin: 20px 0 30px;
  }
  
  .faq-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding: 10px 20px;
    background-color: #f0f0f0;
  }
  
  .faq-header:hover {
    background-color: #e0e0e0;
  }
  
  .faq-header h3 {
    margin: 0;
    font-weight: 900;
    font-size: 20px;
  }
  
  .faq-icon {
    width: 26px;
    height: 26px;
    fill: currentColor;
    transition: transform 0.4s ease-in-out;
    transform: rotate(90deg);

  }
  
  .rotate {
    transform: rotate(0deg);
  }
  

  .faq-content p {
    margin: 0;
    font-size: 20px;
    padding: 20px ;
  }
  
  .faq-content {
  max-height: 0;
  overflow: hidden;
  transition:  max-height 1s ease-in-out;
  background-color: #f0f0f0;


  }
  
  .faq-content.open {
    max-height: 800px;
  }
  

  