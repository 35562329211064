@import '../../color-vars.css';

.gallery-page .footer {
    display: none;
}

.footer-logo {
    cursor: pointer;
}

.footer {
    background: var(--color-green);
}

.footer .meta {
    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 20px 0 40px;
}

.footer .svg {
    max-width: 175px;
    height: auto;
}

.footer a {
    color: #FFF;
}

.footer .inner-row {
    display: flex;
    gap: 10px;
}


.footer a:hover,
.footer .icons-grid a:hover i {
    color: var(--color-yellow);
    transition: color .4s ease;
}

.footer .icons-grid {
    margin-top: 30px;
    display: flex;
    gap: 20px;
}

.footer .icons-grid span {
    display: none;
}

.footer .icons-grid i {
    font-size: 26px;
    color: #FFF;
}