.font-selector select {
    padding: 5px 10px;
    font-size: 18px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.change-font,
.change-font:hover {
    color: #FFF;
}

.font-option {
    cursor: pointer;
    margin-bottom: 10px;
    font-size: 18px;
    border-radius: 20px;
  transition: background .4s ease;

}


.font-option.active {
    background: #000 !important;
    color: #FFF !important;
}

.font-option:hover {
    opacity: .7;
}
.font-option:nth-child(even) {
    background-color: rgb(245, 245, 245);

}
/* .font-options: */