.style-selector {
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    gap: 20px;
    width: 100%;

    .styled-btn {
      overflow: hidden;
      width: 100%;
      padding: 10px 14px;
      
      border-radius: 10px;
      border: none;
      
      font-size: 18px;
      cursor: pointer;

      transition: background 0.4s var(--easing-authentic-motion), color 0.4s var(--easing-authentic-motion);

      &:hover {
        background: var(--color-black);
        color: var(--color-white);
      }
    }
    
    .styled-btn.selected {
      color: var(--color-white);
    }
  }
  
