.analytics-page {
    padding-top: 85px;
    padding-bottom: 50px;
    max-width: 40vw;
    margin: auto;
}

@media (max-width: 991px) {
    .analytics-page {
        overflow: hidden;
    }

    .analytics-page {
        max-width: 95vw !important;
    }

    .stats-card {
        width: 40% !important;
    }
}

.options {
    display: flex;
    gap: 0 10px;
    justify-content: center;
    flex-wrap: wrap;
}

.options>div {
    background-color: var(--color-deep-blue);
    color: #fff;
    padding: 4px 8px;
    border-radius: 10px;
    cursor: pointer;
}

.selected {
    background-color: var(--color-green) !important;
}

.heading {
    font-size: 18px;
    border-bottom: 1px solid #c7c7c7;
    color: var(--color-black);
    margin-bottom: 10px;
    font-weight: 900;
}

.heading.mt-4 {
    margin: 0 20px;
}

.count {
    font-size: 34px;
    margin-bottom: 0;
    font-weight: 300;
    color: var(--color-green);

}

.inner-stat {
    font-weight: 300;
    font-size: 20px;
    margin: 0;
    padding: 15px;
    color: var(--color-green);

}

.stats-card .meta .inner-stat:nth-child(even) {
    background: #f3f3f3;
    border-radius: 10px;
}

.viewing-date {
    border: none;

    background: #f3f3f3;
    border-radius: 10px;

    text-align: center;
    padding: 5px;
    font-size: 16px;
}

.stats {
    display: flex;
    gap: 2rem;
    justify-content: center;
    flex-wrap: wrap;
}

.refs {
    font-size: 16px;
    color: var(--color-green);
}

.stats-card {
    border: 1px solid #dee2e6;
    transition: 0.3s;
    border-radius: 20px;
    padding: 16px;
    height: fit-content;
    width: 42%;
}

.stats-card.big {
    width: 90% !important;
}


.error {
    font-size: 18px;
    font-weight: 900;
    padding-bottom: 35vh;
    text-align: center;
}
