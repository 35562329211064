.slideshow-columns {
  position: relative;
  overflow: hidden;

  .visible {
    opacity: 1;
  }

  .fade-out {
    opacity: 0;
  }

  .fade-in {
    opacity: 1;
  }

  .meta {
      transition: opacity 0.5s ease-in-out;
  }

  .column {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    max-width: 25%;
    border-radius: 8px;

    &.no-text {
      justify-content: center;
    }

    img {
      max-width: 100%;
      min-height: 215px;
      max-height: 215px;
      height: auto;
      border-radius: 8px;
    }

    h3 {
      margin: 10px 0;
      font-size: 24px;
      color: #FFF;
    }

    p {
      margin: 0;
      font-size: 16px;
      color: #666;
    }

    .button {
      background: var(--color-yellow);
      border: none;
      border-radius: 30px;
      color: #000;
      font-size: 18px;
      margin-top: 10px;
      padding: 10px 40px;
      text-align: center;
      transition: all .4s ease;
      width: fit-content;

      &:hover {
        background-color: #0056b3;
        color: var(--color-yellow);
      }
    }
  }

  .arrow {
    background-color: #000;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 20px;
    font-weight: 600;
    color: var(--color-yellow);
    display: block;

    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    width: fit-content;
    left: 20px;

    &.right {
      left: auto;
      right: 20px;
    }
  }

  .slideshow {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .slideshow-container {
    width: 100%;
    max-width: 640px;
    margin: auto;
    position: relative;
    overflow: hidden;
  }

  .mobile-nav {
    display: none;
  }

  @media (max-width:768px) {
    .meta {
      display: grid;
      grid-template-columns: 1fr 1fr;
      padding-bottom: 0;
      padding-top: 5px;
    }

    .column {
      margin-bottom: 10px;
      max-width: 100%;

      img {
        max-height: 100%;
        min-height: 115px;
        max-width: 100%;
        width: auto;
      }

      h3 {
        font-size: 15px;
      }

      .button {
        padding: 5px 40px;
        font-size: 14px;
      }
    }

    .arrow {
      display: none
    }

    .mobile-nav {
      display: flex;
      justify-content: space-between;
      // gap: 5px;

      .arrow {
        transform: none;
        position: relative;
        top: auto;
        left: auto;
        padding: 0 30px;
        display: block;

        &.right {
          left: auto;
          right: auto;
        }
      }
    }
    
  }
}
