:root {
  --color-bright-blue: #a4edec;
  --color-deep-blue: #5883ff;
  --color-green: #06826b;
  --color-yellow: #ffbd58;
  --color-light-yellow: #fdf7e1;
  --color-black: #000;
  --color-white: #FFF;
  --easing-authentic-motion: cubic-bezier(0.4, 0, 0.2, 1);
}
