@import "../../color-vars.css";

.auth {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px;
  min-height: 100vh;
}

.auth .auth-inner-bubble-container {
  background-color: rgba(165, 165, 165, 0.125);
  padding: 30px;
  border-radius: 30px;
  /* border: 2px solid var(--color-bright-blue); */
  width: 60%;
  max-width: 500px;
}

p.sign-up-sub {
  font-size: 14px !important;
  color: #000 !important;
}

.bg {
  content: "";
  height: 100%;
  width: 100%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  z-index: -1;

  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  opacity: 1;

  transition: opacity 0.2s ease;
}

body.has-scrolled-100vh .bg {
  opacity: 0;
}

body.signup-body .bg {
  background-color: rgb(244,244,244);
}

body.login-body .bg {
  background-color: rgb(244,244,244);
}

body.forgot-body .bg {
  background-color: rgb(244,244,244);
}

body.reset-body .bg {
  background-color: rgb(244,244,244);
}

.signup-body .footer,
.forgot-body .footer,
.reset-body .footer,
.login-body .footer,
.logged-in .footer {
  display: none;
}

.auth h2 {
  font-size: 36px;
  color: #fff;
  text-align: center;
}

.auth h3 {
  color: var(--color-green);
  text-align: center;
  width: 100%;
}

.auth p {
  color: black;
  text-align: center;
  font-weight: 400;
  font-size: 17px;
  color: rgba(17, 17, 17, 0.4);
}

.auth .item {
  margin-bottom: 10px;
}

.auth label {
  font-size: 16px;
  color: #000;
  display: block;
}

.reset-body .auth label {
  color: #000;
}

.auth input,
.auth .input-group-text {
  height: 35px;
  border-radius: 5px !important;
  border: none;
  background-color: white;
  opacity: 1 !important;
}

.auth .input-group-text {
  display: none;
}

.auth input {
  border-left: none;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  font-size: 17px;
  opacity: 0.6;
  width: 100%;
}

.auth i {
  opacity: 0.4;
}

.auth h6 {
  text-align: center;
}

.auth .input-group-text {
  border-right: none;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.auth button,
.auth button:focus,
.auth button:hover {
  -webkit-appearance: none;
  width: 100%;
  height: 47px;
  background-color: var(--color-green);
  border-radius: 50px;
  font-size: 17px;
  color: #FFF;
  border: 0;
  margin-top: 17px;
}

.auth .hidden {
  display: none;
}

.auth .forgot-password {
  margin-top: 0px;
  text-align: end;
  color: #000;
}

.auth .forgot-password a {
  color: #000;
  text-decoration: underline;
}

.auth .end-text {
  display: flex;
  justify-content: center;
  gap: 6px;
  align-items: center;
  margin-top: 23px;
  font-weight: 600;
}

.auth a {
  color: #fff;
}

.auth input:focus {
  box-shadow: none;
}

.auth .form-control:disabled {
  background-color: #e9ecef;
  cursor: not-allowed;
}

.cta-signup {
  font-size: 44px;
  line-height: 1;
  text-align: center;
  cursor: pointer;
  width: fit-content;
  display: block;
  color: #fff;
  margin: 0 auto 35px;
  font-weight: 600;

  background-color: var(--color-green);
  border-radius: 30px;
  padding: 20px 30px;
}

.cta-signup:hover {
  color: #fff;
  opacity: 0.85;
}

.cta-text {
  text-align: center;
  margin: 0 auto 30px;
  width: 100%;
}

.full-title {
  text-align: center;
  margin: 40px 0 60px;
}

.captcha-item {
  display: flex;
  justify-content: center;
}

form .back-btn {
  opacity: .75;
}

@media (max-width: 500px) {
  .auth {
    /* margin-top: 100px; */
  }
  .auth label {
    font-size: 14px;
  }
  .cta-signup {
    font-size: 24px;
  }
  .auth .auth-inner-bubble-container {
    width: 90%;
    padding: 20px;
  }
}

.g-recaptcha > div > div {
  display: flex;
  place-content: center;
  margin-top: 1rem;
}

.plans-info {
  background: #fff;
  position: absolute;
  top: 100vh;
  width: 100%;
}

.plans-info-meta {
  max-width: 900px;
  margin: 50px auto;
  box-sizing: border-box;
  padding: 0 20px;

  font-size: 18px;
}

.plans-info-meta .columns-container .meta {
  padding: 0;
}

.signup-page .columns-container {
  background: transparent;
}

.signup-page h1,
.signup-page h2,
.signup-page h3 {
  color: var(--color-green) !important;
}

.signup-page .columns-container p {
  color: #000;
}
