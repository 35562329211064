.columns-container {
  background: #000;
}


.columns-container .meta {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  padding: 20px 100px 40px;
  max-width: 1400px;
  margin: 0 auto;
}

.columns-container .column {
  background: none;
  padding: 10px;
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
  color: #fff;
  font-size: 18px;
  background-color: transparent;
}

.columns-container .column h3 {
  font-size: 30px;
  font-weight: 600;
  margin: 5px 0 0;
  margin: 22px 0 16px;
  text-align: left;
  line-height: 1.1;
  color: #FFF;
}

.columns-container .column img {
  max-width: 100%;
  width: 100%;
  height: auto;
  aspect-ratio: 5 / 4;
  object-fit: cover;
  border-radius: 30px;
}

.columns-container .column .button {
  margin-top: 10px;
  padding: 10px 40px;
  width: 100%;
  text-align: center;
  background: var(--color-yellow);
  color: #000;
  border: none;
  border-radius: 30px;
  font-size: 18px;
  transition: 0.4s all ease;
}

.columns-container .column .button:hover {
  background: #FFF;
  color: #000;
}

.column.no-text {
  text-align: center;
}

.column.no-text h3 {
  text-align: center;
  margin-bottom: 30px;
}

@media (max-width: 1024px) {
  .columns-container .meta {
    padding: 40px;
    gap: 0;
  }
}

/* Phone Breakpoint */
@media (max-width: 768px) {
  .columns-container .meta {
    padding: 20px;
    flex-direction: column;
  }
  .columns-container .column .button {
    font-size: 16px;
    padding: 10px 15px;
    display: block;
  }
  div.columns-container h2 {
    padding-top: 30px;
  }  
  .column.no-text h3 {
    margin-top: 20px;

    margin-bottom: 20px;
  }
  
}
