.GalleryPage {
    position: fixed;
    top: 0px;
    width: 100%;
    isolation: isolate !important;
    height: 100vh !important;
    height: -webkit-fill-available;
    overflow-y: hidden !important;
    z-index: 100;
}

.GalleryPage .upload-form {
    width: 100%;
    margin: 0px 0px;
}

.GalleryPage .tip {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    color: #FFF;
    font-size: 100px;
    filter: drop-shadow( 0 0 1px #000);

}

.user-drawer.ant-drawer-open .ant-drawer-content-wrapper {
    width: 100% !important;
}

.panel-grid {
    display: flex;
    /* grid-template-columns: 1fr 1fr; */
    gap: 5px 0;
}
  
.grid-column {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.panel-item.activeLink {
    background: rgba(var(--theme-color), 0.3);
}

.panel-item {
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    padding: 10px;
    height: max-content;
    cursor: pointer;
    font-size: .75rem;
    text-align: center;
}

.panel-item img {
    width: 100%;
    object-fit: cover;
    border-radius: 10px;
    aspect-ratio: 3 / 4;
}

.panel-item.is-horizontal img {
    aspect-ratio: 4 / 3;
}

.ant-drawer a {
    color: #000;
}

.ant-drawer .ant-drawer-close {
    padding: 0;
    margin: 0;
}

.ant-drawer-header-close-only {
    padding: 35px 24px;
}

.ant-drawer-header-title {
    justify-content: flex-end;
}

.anticon-close  path {
    fill: #000;
}

.GalleryPage .three-dots {
    position: fixed;
    top: 23px;
    right: 23px;
    z-index: 1000 !important;
    width: 100%;
    filter: drop-shadow( 0 0 1px #000);
}

.GalleryPage .three-dots>div {
    margin-left: 43px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.GalleryPage .three-dots>div a {
    text-decoration: none;
}

.GalleryPage .three-dots .anticon {
    vertical-align: middle !important;
}

.ant-drawer-content a {
    text-decoration: none;
}

.ant-drawer-content a.activeLink {
    text-decoration: underline;
}

.GalleryPage .logos {
    position: fixed;
    bottom: 50px;
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: center;
    width: 100%;
    filter: drop-shadow( 0 0 1px #000);
    z-index: 50;
  }
  
  .GalleryPage .logos a {
    padding: 5px;
    font-size: 20px;
    color: #FFF !important;
  }


  .GalleryPage .logos a path {
    fill: #FFF !important;
    text-shadow: 0 0 1px black;
  }


.try-tapfolio {
    position: absolute;
    left: 0;
    top: 0;
    padding: 15px 25px;
    display: block;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    text-align: right;
    font-weight: 400;
    text-decoration: underline !important;
}

.try-tapfolio.text-links {
    font-size: 12px;
    padding: 6px 25px;
}

.info-button {
    cursor: pointer;
}

.hidden-input {
    display: none;
}

.copy-area {
    display: flex;
    gap: 10px;
    align-items: center;
}

.copy-feedback {
    opacity: 0;
    transition: 1s opacity ease-in-out;
}

.copy-feedback.active {
    opacity: 1;
}

.copy-area-in-modal {
    color: rgba(0, 0, 0, 0.85);
    font-weight: 400;
    font-size: 18px;
    position: absolute;
    top: 30px;
    left: 30px;
}

.copy-area-in-modal .copy-feedback  {
    font-size: 16px;
    margin-left: 5px;
}



.copy-logo {
    animation: shake 6s cubic-bezier(.36,.07,.19,.97) forwards infinite;
    transform: translate3d(0, 0, 0);
  }
  
  @keyframes shake {
    10%, 90% {
      transform: translate3d(-1px, 0, 0);
    }
    
    20%, 80% {
      transform: translate3d(1px, 0, 0);
    }
  
    30%, 50%, 70% {
      transform: translate3d(-1px, 0, 0);
    }
  
    40%, 60% {
      transform: translate3d(1px, 0, 0);
    }
  }

    body.in-app-browser .GalleryPage .logos {
        bottom: 65px;
    }

    body.in-app-browser .GallaryCard .swiper-slide .desc {
        height: 60px;
    }


@media(max-width:500px) {
    .GalleryPage .logos {
        bottom: 25px;
    }
}

.GalleryPage .threads {
    position: relative;
    top: -2px;
}

.GalleryPage .threads path,
.GalleryPage .threads {
    width: 18px;
    height: 18px;
}