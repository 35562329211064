@import "../../color-vars.css";

.home h2 {
  font-size: 60px;
  font-weight: 900;
}

.home section {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 50px;
}

.home .threads path,
.home .threads {
  min-width: 18px;
  height: 18px;
  fill: #fff;
  margin-right: 8px;
  position: relative;
}

.hero-bg {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
  opacity: 0.2;
}

@media (min-width: 768px) {
  .home .threads {
    top: -4px;
  }
}

.home h5 span {
  font-size: 20px;
  color: var(--color-green);
}

.home .top-section {
  background-color: #000;
  overflow: hidden;
}

.home section.full-width {
  max-width: 100%;
  margin: 0 auto;
  padding: 0;
}

.home .two-col {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0 20px;
}
.home .two-col {
  height: 100svh;
}

.home .left {
  flex: 1;
  max-width: 50%;
  text-align: center;
  position: relative;
  z-index: 20;
}

.home .left img {
  max-height: 550px;
  width: 100%;
  object-fit: contain;
}

.home .right {
  flex: 1;
  position: relative;
  z-index: 20;

  max-width: 1000px;

  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.home .phone-image {
  width: 100%;
}

.home .right p {
  color: #fff;
  font-size: 32px;
  line-height: 36px;
  font-weight: 400;
  letter-spacing: 0.75px;
  margin-bottom: 25px;
}

.home .form-text {
  margin: 0;
  background-color: #fff;
  border-radius: 12px 0 0 12px;
  width: 300px;
  height: 50px;

  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 0 10px;
}

.home .form-text .form-control {
  width: 100%;
  background-color: transparent;
  border: none;
  padding-left: 0;
}

.home .form-text .form-control:focus {
  outline: none !important;
  box-shadow: none;
}

.placeholder {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  opacity: 0.7;
}

.main-cta-btn[disabled]:hover {
  background-color: var(--color-deep-blue) !important;
  border-color: var(--color-deep-blue) !important;
}

.home .logos {
  position: absolute;
  left: 50%;
  bottom: 0;
  color: #fff;
  transform: translate(-50%, 100%);
  margin-bottom: -50px;
}

.home .logos i {
  padding: 0 8px;
  font-size: 20px;
}

.home h1 {
  color: var(--color-bright-blue);
  font-size: 60px;
  line-height: 65px;
  font-weight: 900;
  margin: 0;

  margin-bottom: 10px;
}

.home h1 span {
  display: inline-block;
  margin-left: 10px;
}

.home .two-col-full-width {
  display: flex;
  align-items: center;
  justify-content: center;
}

.home .left-full-width {
  flex: 1;
  min-height: 850px;
  background-color: var(--color-deep-blue);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 100px;
  box-sizing: border-box;
  flex-direction: column;
}

.home .right-full-width {
  min-height: 850px;
  flex: 1;
  display: flex;
  min-width: 50vw;

  position: relative;
  overflow: hidden;

  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ff8117+0,f8684d+100 */
  background: #ff8117; /* Old browsers */
  background: -moz-linear-gradient(
    top,
    #ff8117 0%,
    #f8684d 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    #ff8117 0%,
    #f8684d 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    #ff8117 0%,
    #f8684d 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff8117', endColorstr='#f8684d',GradientType=0 ); /* IE6-9 */
}

.home .right-full-width .track {
  position: absolute;
  width: 25%;
  height: 1850px;

  background-size: 100%;
  background-repeat: no-repeat;
  transform-origin: center;
  animation: silde-down 45s linear infinite alternate;
}

.home .right-full-width .right-meta {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 125%;
  height: 125%;
  transform: translate(-50%, -50%) rotate(-45deg);
}

.home .right-full-width .track-one {
  left: 0;
  background-image: url("https://res.cloudinary.com/dhbf9ny7k/image/upload/v1692822978/Tall-Banner-2.0-1_y6s6m0.png");
}

.home .right-full-width .track-two {
  left: 25%;
  background-image: url("https://res.cloudinary.com/dhbf9ny7k/image/upload/v1692822979/Tall-Banner-2.0-2_ckhdtq.png");
  animation: silde-down 45s linear infinite alternate-reverse;
}

.home .right-full-width .track-three {
  background-image: url("https://res.cloudinary.com/dhbf9ny7k/image/upload/v1692822979/Tall-Banner-2.0-4_p18quz.png");
  left: 50%;
}

.home .right-full-width .track-four {
  background-image: url("https://res.cloudinary.com/dhbf9ny7k/image/upload/v1692822979/Tall-Banner-2.0-3_ykan6o.png");
  animation: silde-down 45s linear infinite alternate-reverse;
  left: 75%;
}

@keyframes silde-down {
  from {
    transform: translate(0%, calc(50% + -100%));
  }
  to {
    transform: translate(0%, calc(1850px - 100%));
  }
}

.home h2 {
  color: #fff;
  text-align: center;
  font-weight: 300;
  font-size: 70px;
  line-height: 75px;
  margin: 0 0 20px;
}

.home h4 {
  color: var(--color-green);
  text-align: center;
  font-weight: 400;
  font-size: 32px;
  line-height: 36px;
  max-width: 220px;
}

.home .use-case {
  color: #fff;
  margin: 15px;
  padding: 20px;
  min-width: 275px;
  text-align: center;
  border-radius: 50px;
  font-weight: 400;
  font-size: 40px;
  line-height: 40px;
}

.home .use-case:nth-of-type(1) {
  background-color: var(--color-green);
}

.home .use-case:nth-of-type(2) {
  background-color: var(--color-yellow);
}
.home .use-case:nth-of-type(3) {
  background-color: var(--color-deep-blue);
}

.home h3 {
  text-align: center;
  font-size: 100px;
  color: var(--color-green);
  font-weight: 300;
  margin: 0;
}

.home .cols {
  display: flex;
  justify-content: center;
  gap: 30px;
}

.home .bottom .column {
  padding: 5px 30px 35px;
  background-color: #f1f6f2;

  border-radius: 10px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.gradient-border {
  --borderWidth: 2px;
  position: relative;
  border-radius: var(--borderWidth);
}
.gradient-border:after {
  content: "";
  position: absolute;
  top: calc(-1 * var(--borderWidth));
  left: calc(-1 * var(--borderWidth));
  height: calc(100% + var(--borderWidth) * 2);
  width: calc(100% + var(--borderWidth) * 2);
  background: linear-gradient(
    60deg,
    #06826b,
    #5883ff,
    #06826b,
    #f99c11,
    #06826b,
    #a4edec,
    #5883ff,
    #06826b
  );
  border-radius: 10px;
  z-index: -1;
  animation: animatedgradient 3s ease alternate infinite;
  background-size: 300% 300%;
}

@keyframes animatedgradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.home .bottom {
  padding: 100px 0;
  box-sizing: border-box;
}

.home .bottom h4 {
  width: 100%;
  max-width: 100%;
  margin-top: 20px;
  color: #000;
  font-size: 24px;
  text-align: center;
  font-weight: 600;
}

.home .bottom p {
  width: 100%;
  max-width: 100%;

  max-width: 700px;
  margin: 0 auto 25px;
  text-align: center;

  font-size: 18px;
}

.home .url {
  color: var(--color-bright-blue);
  font-size: 24px;
  position: relative;
  margin: 0;
}

.over-flow {
  overflow-y: hidden;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.holder {
  opacity: 0;
}
.uris {
  position: absolute;
  top: 0;
  left: 100px;
  display: flex;
  flex-direction: column;

  animation: slide-up 8s linear infinite alternate;
}

@keyframes slide-up {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(calc(-100% + 37px));
  }
}

.home .form-button {
  background: var(--color-green);
  color: #fff !important;
  border-radius: 0 12px 12px 0;
  font-size: 16px;
  box-shadow: none !important;
  border: 0 !important;
  text-shadow: none;
}

.home .mobile {
  display: none !important;
}

.home .bottom-fields {
  border-radius: 30px !important;
  border-color: var(--color-green) !important;
  font-size: 16px;
  margin: auto;
}

.home .info-section {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.home .bottom-cta {
  background-color: var(--color-green) !important;
  text-align: center;
  border-radius: 50px;
  color: #fff !important;
  font-size: 16px;

  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
}

.home .pricingCard .main {
  height: 300px;
  text-align: center;
  padding: 17px;
}

.pricingCard ul {
  font-size: 18px;
  margin: 25px auto;
  color: var(--color-green);

  max-width: 250px;
  min-width: 250px;
  text-align: center;

  padding: 0;

  display: flex;
  flex-direction: column;
}

.pricingCard li {
  list-style: none;
}

.home .pricingCard .btn {
  box-sizing: border-box;
  cursor: pointer;
  display: inline-flex;

  align-items: center;

  justify-content: center;
  text-decoration: none;
  font-size: 35px;
  line-height: 35px;
  font-weight: 400;
  color: #fff;
  padding: 18px 0px;

  border-radius: 50px;
  margin-bottom: 30px;

  background: var(--color-green);
  max-width: 250px;
  min-width: 250px;

  box-shadow: 2px 2px 0 rgba(21, 21, 21, 0.35);

  transition: all ease 0.4s;
}

.home .pricingCard .btn:hover {
  box-shadow: 5px 5px 0 rgba(21, 21, 21, 0.2);
}

.pricingCard h5 {
  font-size: 30px;
  color: var(--color-deep-blue);
}

.cta-clicked .form-text {
  animation: glowing 1500ms infinite;
}

.home .swiper-button-next:after,
.home .swiper-button-prev:after {
  opacity: 0;
}

@keyframes glowing {
  0% {
    box-shadow: 0 0 10px var(--color-yellow);
  }
  50% {
    box-shadow: 0 0 30px var(--color-yellow);
  }
  100% {
    box-shadow: 0 0 10px var(--color-yellow);
  }
}

#better .left-full-width {
  background-color: transparent;
  align-items: flex-end;
  max-width: 420px;
  padding-left: 0;
  min-height: 820px;
}

#better .right-full-width {
  background: transparent;
  align-items: center;
  flex: none;
  min-width: auto;
  min-height: 820px;
}

.home section#better .swiper-button-next:after,
.home section#better .swiper-button-prev:after {
  opacity: 1;
}

section#better {
  background-color: #846750;
  /* background-image: linear-gradient(45deg, #cecac7 50%, #e1ddda 50%); */
  background-size: 5px 5px;
}

#better .left-full-width h2 {
  text-align: right;
  font-weight: 900;
  color: #fff;
}

#better .left-full-width p {
  color: #fff;
  font-size: 20px;
  text-align: right;
  line-height: 1.2;
  max-width: 200px;
  font-weight: 300;
}

#better .sample-socials {
  position: absolute;
  bottom: 50px;
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
  width: 100%;
  z-index: 50;

  transform: translate(-50%, 0);
  left: 50%;
  bottom: 33px;
  filter: drop-shadow(0 0 1px #000);
}
#better .sample-socials .icon {
  padding: 5px;
  font-size: 20px;
  color: #fff;
  fill: #fff;
  width: 30px;
}

.phone-chrome {
  position: relative;
  max-width: 420px;
  max-height: 100%;
}

#better .swiper-button-prev,
#better .swiper-button-next {
  transform: translateY(50%);
}

img.sample-logo {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 50;
  margin: 20px;
  filter: drop-shadow(0 0 1px #000);
}

.sample-menu {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 50;
  margin: 20px;
  font-size: 20px;
  filter: drop-shadow(0 0 1px #000);
}

.sample-menu svg path {
  fill: #fff;
}

.coffee-slide {
  width: 100%;
}

@media (max-width: 1160px) {
  .home h1 {
    font-size: 55px;
  }
}

@media (max-width: 991px) {
  #better .right-full-width {
    width: auto;
  }
  .home .mobile {
    display: flex !important;
    position: relative;
    z-index: 20;
  }

  .home .desktop {
    display: none !important;
  }

  .home section {
    padding: 0 25px;
  }

  .home .bottom {
    padding: 40px 25px;
  }

  .home .right p {
    font-size: 23px;
    line-height: 1.2;
    margin-top: 10px;
    margin-bottom: 0;
  }

  .home .form-meta {
    justify-content: center;
    align-items: center;
  }

  .home .form-button {
    width: 100% !important;
    font-size: 16px !important;
  }

  .home .form-text {
    width: 100% !important;
  }

  .home .form-text input {
    width: 100% !important;
    font-size: 16px !important;
  }

  .home .two-col {
    gap: 25px;
  }

  .home .two-col,
  .home .two-col-full-width,
  .home .cols {
    flex-direction: column;
  }

  .home .cols {
    margin-top: 30px;
  }

  .two-col {
    padding-top: 105px;
    padding-bottom: 30px;
  }

  .home h1 {
    line-height: 1;
    font-size: 40px;
  }

  .home h2 {
    line-height: 48px;
    font-size: 48px;
  }

  .home .left-full-width {
    padding: 20px;
    min-height: 75vh;
    width: 100%;
  }

  .home .right-full-width {
    padding: 20px;
    min-height: 50vh;
    width: 100%;
  }

  .home h3 {
    line-height: 60px;
    font-size: 60px;
  }

  .home .logos {
    position: relative;
    left: 0;
    right: auto;
    bottom: 0;
    color: #fff;
    transform: translate(0, 0);
    margin-bottom: 0;
    display: flex;
    justify-content: flex-start;
  }

  .placeholder {
    font-size: 0.85rem;
  }

  .home .form-text .form-control {
    font-size: 0.85rem;
  }

  .home .form-button {
    width: 220px !important;
  }

  .home .right {
    width: 100%;
    flex: 0;
  }

  .home .phone-image {
    height: 65%;
    width: 65%;
  }

  .home .bottom .column {
    margin-top: 0 !important;
    margin-bottom: 0;
  }

  .home .left {
    text-align: center;
    max-width: 100%;
    flex: 0;
  }

  #better .two-col-full-width {
    flex-direction: row;
  }

  #better .left-full-width {
    max-width: 230px;
  }
}

@media (max-width: 500px) {
  .home h1 span {
    display: block;
    margin-left: 0;
    margin-bottom: 0;
  }

  .home .form-meta {
    width: 235px;
    flex-direction: column;
  }

  .two-col {
    padding-top: 40px !important;
  }

  .home .form-button {
    width: auto !important;
    padding: 0 30px;
    border-radius: 12px;
  }

  .home .form-text {
    border-radius: 12px;
    margin-bottom: 20px;
  }

  .pricingCard {
    margin: 20px;
  }

  .two-col {
    padding-top: 0;
    justify-content: center;
  }

  .two-col .left {
    display: none;
  }

  .pricingCard ul {
    min-width: 100%;
  }

  .home .left img {
    height: 100%;
    width: 100%;
    max-width: 280px;
  }

  .home .right-full-width .track {
    height: 900px;
    animation: silde-down 20s linear infinite alternate;
  }

  .home .right-full-width .track-two {
    animation: silde-down 20s linear infinite alternate-reverse;
  }

  .home .right-full-width .track-four {
    animation: silde-down 20s linear infinite alternate-reverse;
  }

  @keyframes silde-down {
    from {
      transform: translate(0%, calc(200px + -100%));
    }
    to {
      /* left: 100%; */
      transform: translate(0%, calc(700px - 100%));
    }
  }

  #better .two-col-full-width {
    flex-direction: column;
  }

  #better .left-full-width {
    max-width: 80%;
    min-height: 225px;
    align-items: center;
    padding-right: 0;
  }

  .home #better .right-full-width {
    padding: 0;
    min-height: 100%;
    width: 100%;
    padding-bottom: 40px;
  }

  #better .left-full-width h2 {
    text-align: center;
    font-size: 33px;
    margin-bottom: 5px;
    font-weight: 900;
  }

  #better .left-full-width p {
    margin: 0 auto 0;
    max-width: 320px;
    text-align: center;
    font-size: 24px;
  }

  .phone-chrome {
    max-width: 80%;
    margin: auto;
  }

  #better .swiper-button-prev,
  #better .swiper-button-next {
    transform: translateY(-50%);
  }

  #better .sample-socials {
    bottom: 28px;
  }

  @media (min-height: 736px) {
    .home .left img {
      max-width: 360px;
    }
  }
}

@media (max-width: 320px) {
  .home .left img {
    max-width: 205px;
  }

  .worm {
    opacity: 0;
  }

  .home h1 {
    line-height: 28px;
    font-size: 28px;
  }
}

.home #analytics-section {
  height: 600px;
  background-color: var(--color-green);
  overflow: hidden;
  position: relative;
}

.home #analytics-section .custom-line {
  transform: translate(-50%, 0) rotate(90deg) scaleY(0);
}

.home #analytics-section.intersected .custom-line {
  transform: translate(-50%, 0) rotate(90deg) scaleY(1);
}

.home .custom-line {
  --size: 50px;
  --b: 8px;
  --p: 25px;
  --R: calc(55.9px + var(--b) / 2);

  transition: transform ease-in-out 0.8s;

  top: 0;
  left: 50%;

  transform: translate(-50%, 0) rotate(90deg) scaleY(1);
  position: absolute;

  --_g: #0000 calc(99% - var(--b)), #000 calc(101% - var(--b)) 99%, #0000 101%;
  -webkit-mask: radial-gradient(
        var(--R) at left 50% bottom calc(-1 * var(--p)),
        var(--_g)
      )
      calc(50% - 2 * var(--size)) calc(50% - var(--size) / 2 - var(--b) / 2) /
      calc(4 * var(--size)) calc(var(--size) + var(--b)) repeat-x,
    radial-gradient(var(--R) at left 50% top calc(-1 * var(--p)), var(--_g)) 50%
      calc(50% + var(--size) / 2 + var(--b) / 2) / calc(4 * var(--size))
      calc(var(--size) + var(--b)) repeat-x;
  background: #fff;
  height: 910px;
  width: 100%;
}

.home .circle span {
  animation: pulse 2s infinite;
}

.home .circle {
  position: absolute;
  top: 90px;
  z-index: 20;
  width: 100px;
  height: 100px;

  left: 50%;
  transform: translate(-125%, 0) scale(1);

  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 30px;
  font-weight: 400;
}

.home .circle:after {
  content: "LINK TAPS";
  font-size: 16px;
  position: absolute;
  white-space: nowrap;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
}

.home .circle.label {
  position: absolute;
  top: 0;
  white-space: nowrap;
  transform: translate(35%, 0);
  font-weight: 900;
}

.home .circle.label:after {
  content: "" !important;
}

.home .swipes {
  top: 190px;
  transform: translate(25%, 0);
}

.home .swipes:after {
  content: "SWIPES";
}

.home .social-taps {
  top: 290px;
  transform: translate(-125%, 0);
}

.home .social-taps:after {
  content: "SOCIAL TAPS";
}

.home .views {
  top: 390px;
  transform: translate(25%, 0);
}

.home .views:after {
  content: "VIEWS";
}

.offset-anchor {
  scroll-margin-top: 50px;
}

@media (max-width: 768px) {
  .home .custom-line {
    width: 200%;
    height: 500px;
  }
  .home #analytics-section {
    height: 600px;
  }
  .offset-anchor {
    scroll-margin-top: 100px;
  }

  .home .bottom h4 {
    font-size: 20px;
  }

  .home .bottom p {
    font-size: 16px;
  }
}

@keyframes pulse {
  0% {
    transform: scale(0.9);
  }

  70% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.9);
  }
}

.worm-meta {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 0;
}

.has-scrolled .worm {
  opacity: 0;
}

.worm {
  width: 2px;
  height: 25px;
  border-top-right-radius: 2px;
  border-top-left-radius: 2px;
  background: #fff;
  transition: opacity 0.4s var(--easing-authentic-motion);
}

.bounce {
  animation: bouncing 1.5s var(--easing-authentic-motion) infinite;
}

@keyframes bouncing {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-1px);
  }
  60% {
    transform: translateY(18px);
  }
}
