.btn-primary {
    background-color: #000;
    border-color: #000;
}
.btn-primary:hover {
    background-color: #333;
    border-color: #000;
}

.ProductCard {
    padding: 20px 30px !important;
}

.ProductCard .swiper {
    width: 100%;
    height: 475px;
    position: relative;
}

.ProductCard .swiper-slide {
    border-radius: 10px;
    box-shadow: 4px 4px 21px rgb(82, 82, 82, .4);
    background-color: white !important;
    z-index: 50 !important;
    /* min-width: 310px; */
}

.ProductCard .swiper-slide img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    z-index: -1000 !important;
}

.ProductCard .swiper-slide .buttons-container {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    z-index: 100;
    border-radius: 5px 5px 0 0;
    background: rgb(255 255 255 / 25%);
    backdrop-filter: blur(10px);
}

span.anticon.anticon-edit {
    padding: 20px;
    filter: drop-shadow( 0 0 1px #000);
    position: relative;
    z-index: 100;
    transition: .4s transform ease;
}

span.anticon.anticon-edit:hover {
   transform: scale(1.25);
}

span.anticon.anticon-edit svg {
    fill: #FFF;
    height: 20px;
    width: 20px;
    z-index: 200;
}  

.ProductCard .swiper-slide .delete {
    filter: drop-shadow( 0 0 1px #000);
    color: #FFF;
    padding: 20px;
    font-size: 15px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000 !important;
    cursor: pointer !important;
    transition: .4s transform ease;
}

.ProductCard .swiper-slide .delete svg {
    height: 20px;
    width: 20px;
    z-index: 200;
}

.ProductCard .swiper-slide .delete:hover {
    transform: scale(1.25);
}

.ProductCard .swiper-slide .delete .anticon {
    z-index: 1000 !important;
    cursor: pointer !important;
    position: relative;
}

.ProductCard .owner {
    position: relative;
    bottom: 10px !important;
    right: 10px !important;
    color: white;
    background-color: rgba(20, 19, 19, 0.6);
    padding: 10px 23px;
    font-size: 15px;
    border-radius: 10px;
    z-index: 1000;
}

.ProductCard .buttons {
    display: flex;
    width: 100%;
    justify-content: space-between !important;
}

.ProductCard .buttons a {
    font-size: 10px;
    padding: 4px;
}

.swiper-pagination.swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: 20px !important;
    width: fit-content !important;
    left: 50%;
    transform: translateX(-50%);
}

span.swiper-pagination-bullet.swiper-pagination-bullet-active {
    background: var(--color-green);
}

@media (max-width: 800px) {
    .ProductCard .swiper {
        height: 400px;
    }
}