.file-preview {
    margin: auto;
    text-align: center;
}

.upload-desc {
    max-height: 3.5em!important;
    min-height: 3.5em!important;
    resize: none;
}

@media only screen and (max-width: 600px) {
    .upload-desc {
        max-height: 5em!important;
        min-height: 5em!important;
        resize: none;
    }    
  }