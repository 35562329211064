.GallaryCard {
  padding: 0px 0px !important;
  position: relative !important;
  height: 100vh;
  overflow-y: hidden !important;
}

.GallaryCard .swiper {
  width: 100%;
}

.info-circle path,
.three-dots path,
.info-circle a,
.three-dots a {
  color: #fff;
}

.GallaryCard a {
  color: #000;
  text-decoration: underline;
}

.swiper-pagination.swiper-pagination-progressbar.swiper-pagination-horizontal {
  mix-blend-mode: difference;
  height: 2px;
}

.swiper-button-prev,
.swiper-button-next {
  filter: drop-shadow( 0 0 1px #000);
}

.swiper-button-prev {
  left: 25px !important;
  justify-content: flex-start !important;
}

.swiper-button-prev:after {
  transform: translate(0, -50%) !important;
}

.swiper-button-next {
  right: 25px !important;
  justify-content: flex-end !important;
}

.swiper-button-next:after {
  transform: translate(0, -50%) !important;
}

.swiper-pagination-progressbar-fill {
  background-color: #fff !important;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  color: #fff;
}

.GallaryCard .swiper-slide {
  border-radius: 0px;
  background-color: #fff;
  height: 100vh;
  overflow-y: hidden !important;
}

.GallaryCard .swiper-slide img {
  height: 100vh;
  object-position: center;
}

.GallaryCard .main-link {
  display: flex;
  align-items: center;
  text-decoration: none;

  position: relative;
  overflow: hidden;
  font-size: 24px;

  font-weight: 400;
  font-family: "Lato";
}

.GallaryCard .main-link:hover .inner-title,
.GallaryCard .main-link:hover svg {
  transform: scale(1.05);
}

.GallaryCard .main-link.no-link:hover .inner-title,
.GallaryCard .main-link.no-link:hover svg {
  transform: scale(1);
}

.GallaryCard .main-link:after {
  content: "";
  display: block;
  position: absolute;
  background: rgba(255, 255, 255, 0.333);
  width: 75%;
  height: 100%;
  top: 0;
  filter: blur(15px);
  transform: translateX(-350px) skewX(-15deg);
  animation: shine 2s ease infinite;
  animation-delay: 1s;
  opacity: 0;
}

.GallaryCard .main-link.no-link:after {
  animation: none;
}

@keyframes shine {
  0% {
    transform: translateX(-350px) skewX(-15deg);
    opacity: 0;
  }
  5% {
    opacity: 1;
  }
  100% {
    transform: translateX(300px) skewX(-15deg);
  }
}

.GallaryCard .inner-title {
  margin: 0;
  color: #fff;
  transform: translateX(0);

  transition: transform .4s ease;
  font-weight: 400;
  /* margin-top: -2px;  */
}

.GallaryCard .lato .inner-title {
  margin-top: 0; 
}

.GallaryCard .icon svg,
.GallaryCard .icon {
  width: 25px;
  min-height: 25px;
  max-height: 25px;
  margin-right: 15px;

  transition: transform .4s ease;
}

.GallaryCard .icon path {
  fill: #fff;
}

.GallaryCard .swiper-slide .title {
  position: absolute;
  top: 45% !important;
  left: 50%;
  transform: translate(-50%, -45%) !important;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  padding: 10px 43px;
  text-align: center;
  font-size: 28px;
  border-radius: 10px;

  z-index: 20;
}

.GallaryCard .swiper-button-prev,
.GallaryCard .swiper-button-next {
  top: 0;
  width: 10vw !important;
  height: 100vh;
}

.GallaryCard .swiper-slide .desc {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -100%);
  text-align: center;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 10px 23px;
  border-radius: 0px;
  font-size: 17px;
  margin-top: 0px;
  z-index: 1000;
  color: #fff !important;

  font-family: "Lato";
}

.GallaryCard .swiper-slide .desc,
.GallaryCard .swiper-slide .desc p,
.GallaryCard .swiper-slide .desc a,
.GallaryCard .swiper-slide .desc span,
.GallaryCard .swiper-slide .desc h1,
.GallaryCard .swiper-slide .desc h2,
.GallaryCard .swiper-slide .desc h3,
.GallaryCard .swiper-slide .desc h4,
.GallaryCard .swiper-slide .desc h6,
.GallaryCard .swiper-slide .desc h5 {
  color: #fff !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.GallaryCard .buttons {
  display: flex;
  justify-content: space-between;
}

.GallaryCard .buttons a {
  font-size: 10px;
  padding: 4px;
}

.GallaryCard .swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 15vh !important;
}

.GallaryCard .buttons {
  justify-content: center;
  gap: 10px;
}

.GallaryCard .buy-section a {
  font-size: 17px;
}

/* Light theme colors */
.gallery-page.light-theme .inner-title {
  color: #000;
}

.gallery-page.light-theme .GallaryCard .icon path {
  fill: #000;
}

.gallery-page.light-theme .GallaryCard i.icon:before {
  color: #000;
}

.gallery-page.light-theme .GallaryCard .swiper-slide .desc {
  color: #000 !important;
}

.gallery-page.light-theme .GallaryCard .swiper-slide .title {
  color: #000 !important;
}

.lazy-load-image-background {
  display: block !important;
  height: 100%;
}

span.bg-color {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

span.lazy-load-image-background {
  position: relative;
  z-index: 10;
}

.light-theme .main-link span.fa-brands.fa-x-twitter {
  color: #000;
  font-size: 26px;
  margin-right: 10px;
}

.main-link span.fa-brands.fa-x-twitter {
  color: #FFF;
  font-size: 26px;
  margin-right: 10px;
}

@media (max-width: 500px) {
  .light-theme .main-link span.fa-brands.fa-x-twitter {
    color: #000;
    font-size: 16px;
    margin-right: 5px;
  }
  
  .main-link span.fa-brands.fa-x-twitter {
    color: #FFF;
    font-size: 16px;
    margin-right: 5px;
  }

  .GallaryCard .swiper {
    width: 100%;
    overflow-y: hidden !important;
  }

   .GallaryCard i.icon {
    min-height: 100%;
    max-height: 100%;
  }
  
  .GallaryCard i.icon:before {
    font-size: 15px;
    vertical-align: middle;
  }

  .GallaryCard .swiper-slide {
    height: 100dvh;
    overflow-y: hidden !important;
  }

  .GallaryCard .icon svg,
    .GallaryCard .icon {
    width: 15px;
    min-height: 15px;
    max-height: 15px;
    margin-right: 8px;
  }

  .GallaryCard .bebas-neue .icon svg {
    margin-right: -2px !important;
  }

  .GallaryCard .swiper-slide img {
    height: 100dvh;
  }

  .GallaryCard .inner-title,
  .GallaryCard .button-inside {
    font-size: 18px;
  }

  .GallaryCard .swiper-slide .title {
    top: 50% !important;
    left: 50%;
    transform: translate(-50%, -50%) !important;
    padding: 8px 14px;
    border-radius: 10px;
    margin-bottom: 0px;
    margin-top: 0px;
    width: max-content;
    
    display: flex;
    justify-content: center;

  }

  .GallaryCard .swiper-slide .desc {
    position: absolute;
    top: 100% !important;
    left: 50%;
    text-align: center;
    width: 100% !important;
    background-color: rgba(0, 0, 0, 0.6);
    padding: 0 15px;
    border-radius: 0px;
    font-size: 14px;
    margin-top: 0px;
    z-index: 1000 !important;
    word-wrap: break-word !important;
  }

  .GallaryCard .swiper-slide .desc p:first-child {
    padding-top: 4px;
  }

  .GallaryCard .swiper-slide .desc p:last-child {
    padding-bottom: 4px;
  }

  .GallaryCard .swiper-button-prev,
  .GallaryCard .swiper-button-next {
    height: 100dvh;
  }

  .swiper-button-prev:after {
    transform: translate(0, 50%) !important;
  }

  .swiper-button-next:after {
    transform: translate(0, 50%) !important;
  }

  .GallaryCard .swiper-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal,
  .swiper-pagination-custom,
  .swiper-pagination-fraction {
    bottom: 4vh !important;
  }
}
