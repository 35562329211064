@import "antd/dist/antd.css";
@import "color-vars.css";

html,
body {
  height: auto;
  font-family: 'Lato', sans-serif !important;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 600;
}

body {
  margin: 0;
  width: 100%;

  background-color: #fff;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

html .btn-primary.disabled, 
html .btn-primary:disabled {
  background-color: #000;
  border-color: #000;
  opacity: .35;
}

html .App {
  overflow-x: hidden !important;
}

html .account-app {
  margin: 95px 0 50px;
}

html .account-body .account-app {
  margin: 100px 0 50px;
}

.ant-message .anticon {
  vertical-align: text-top !important;
}

.btn {
  border-radius: 50px;
}

.Toastify__toast {
  border-radius: 15px !important;
}

@media (max-width: 500px) {
  html {
    height: 100svh;
  }
}