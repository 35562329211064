.panel {
  position: fixed;
  top: 0;
  right: 0; 
  width: 100%;
  height: 100vh;
  background-color: rgba(255,255,255,0.85); 
  z-index: 10;

  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100lvh;

  text-align: center;

  transition: transform 0.4s var(--easing-authentic-motion);

  backdrop-filter: blur(3px);

  transform: translateX(100vw);
}

@media (max-width: 768px) {
.panel {
    display: flex;
  }
}

.panel .title {
  font-size: 20px;
}

.panel.toggled {
  transform: translateX(0);
}

.panel ul.menu {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px 0;
  height: auto;
}

.panel a {
  font-size: 30px !important;
  font-weight: 900;
  color: var(--color-green) !important;
}