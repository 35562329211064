html .App {
    overflow: visible !important;
}

.dashboard-body .footer {
    display: none;
}

.Dashboard .upload-form {
    display: flex;
    // flex-direction: column;
    justify-content: center;
    align-items: center;
    /* margin: 30px 0px; */
    padding: 0 20px;
    box-sizing: border-box;
    text-align: center;

  }
  
  .Dashboard .page.empty {
 height: calc(100vh - 90px);
 display: flex;
    flex-direction: column;
    justify-content: center;
}

.Dashboard .upload-form form {
    width: 36%;
    min-width: 330px;
    max-width: 40vw;
}

.logged-in .page h4 {
  position: fixed;
  z-index: 1000;
  left: 50%;
  top: 38px;
  transform: translate(-50%, 0);
}

.Dashboard .ProductCard {
    max-width: 40vw !important;
    min-width: 100%;
}

.Dashboard .extras {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 25px;
    padding: 0 20px;
}

.Dashboard .extras .btn {
  background: var(--color-deep-blue) !important;
  border-color: var(--color-deep-blue) !important;
}

.Dashboard .color-picker {
    margin: auto;
    padding: 0 20px;
    display: flex;
    justify-content: center;
    
}

.Dashboard .meta-picker {
    box-sizing: border-box;
    border-radius: 10px;
}

.Dashboard .page {
    min-width: 375px;
    margin: auto;
}

.Dashboard .trans-picker {
  display: flex;
  justify-content: center;
}

/* Dashboard.scss - Add these styles */
.Dashboard .sliding-panel {
  position: fixed;
  left: 0;
  bottom: 0;
  transform: translateY(100%);
  width: 100%;
  height: 100%;
  // background: #e8e2de;
  background: RGB(255 255 255 / 85%);
  // border-radius: 5px 5px 0 0;
  backdrop-filter: blur(10px);

  box-shadow: 1px 0 3px rgba(0, 0, 0, 0.25);
  transition: transform 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  z-index: 1000;
  border-radius: 5px 5px 0 0;

  max-height: 50vh;
}

.Dashboard .sliding-panel.open {
  transform: translateY(0%);
}
.Dashboard .toggle-btns {
  display: flex;
  gap: 5px;
}
.Dashboard .btn.toggle {
  color: #FFF;
}

.Dashboard .panel-content {
  padding: 20px 10px;
  overflow-y: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.Dashboard .panel-content .close-btn {
  background: #000 !important;
  color: #FFF !important;
  border-color: #000 !important;
}

.Dashboard .panel-content .btn {
  color: #FFF;
  margin-top: 0 !important;
}

.Dashboard .panel-meta {
  width: 100%;
  height: 100%;
  margin: auto;
  position: relative;
  margin: 0;
}

.Dashboard .inner-slider {
  height: 100%;
  padding-bottom: 60px;
    overflow: auto;

    -webkit-overflow-scrolling: touch;
    max-width: 500px;
    margin: 0 auto;
}

.Dashboard .slider-buttons {
  position: absolute;
  left: 50%;
  bottom: 0;
  width: 100%;
  padding: 5px;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  transform: translateX(-50%);
  
  max-width: 500px;
  margin: 0 auto;

  background: RGB(152 183 178 / 35%);
  backdrop-filter: blur(10px);
  border-radius: 5px 5px 0 0;

  clip-path: inset(0% 0% 0% 0% round 20px);

}

.Dashboard .circle-picker {
    width: 100% !important;
    justify-content: center;
    gap: 20px;
    padding: 15px;

}

.Dashboard .circle-picker span div {
  margin: 0 !important;
}

.Dashboard .extras a {
    width: fit-content;
}

.Dashboard .dash-gallery {
    position: relative;
}

.Dashboard .dash-gallery button.btn.text-white.example-btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 50;
    
    &.no-title {
      display: none;
    }
}

.Dashboard .swiper-button-next:after {
    transform: none !important;
  }
  
  .Dashboard .swiper-button-prev:after {
    transform: none !important;
  }

.intro {
    text-align: center;
    margin: 20px 0;
    font-size: 15px;
    padding: 0 40px;
}

.loading-text {
    margin-top: 20px;
}

.Dashboard .btn:not(.Dashboard .example-btn) {
    background: var(--color-green);
    border-color: var(--color-green);
}

.Dashboard .btn:not(.Dashboard .example-btn) i {
    font-size: 20px;
}

.Dashboard .btn.empty-array {
    position: relative !important;
    top: 0 !important;
    right: 0 !important;
    border-radius: 50px !important;
}

.Dashboard .example-btn {
    background: var(--color-black);
    color: #fff;
    transition: border-radius 0.4s ease-in-out;
    white-space: nowrap;
}

.square {
  border-radius: 0 !important;
}

.rounded {
  border-radius: 50px !important;
}

.Dashboard .mario .button-inside {
  height: 30px;
  white-space: nowrap;
}

.Dashboard .mario.bebas-neue .button-inside {
  line-height: 1.65 !important;
}

.gallery-page  .mario.bebas-neue .button-inside {
  line-height: 1.75 !important;
}

.bebas-neue .inner-title {
  line-height: 1.75 !important;
  margin-bottom: -2px !important;
}

.Dashboard .mario.neuton .button-inside {
  line-height: 1.3 !important;
}

.gallery-page  .mario.neuton .button-inside {
  line-height: 1.5 !important;
  padding-bottom: 5px;
}

.mario {
    --bevel: 3px;
    --border-width: 3px;

    background: transparent !important;
    padding: 0 !important;
    border: none !important;
  
  .button-shadow {
    --padding: calc(var(--border-width) * 2);
    --bevel-1: calc((var(--bevel) + var(--border-width)) - (var(--border-width) * #{tan(22.5deg)}));
    --bevel-2: calc(var(--bevel-1) + var(--border-width));
    --bevel-3: calc(var(--bevel-2) + var(--border-width));
    display: block;
    padding: calc(var(--border-width) * 2) var(--padding) var(--padding) calc(var(--border-width) * 2) ;
    background-color: #000;
    clip-path: polygon(
      var(--bevel-2) var(--border-width),
      calc(100% - var(--bevel-2)) var(--border-width),
      100% var(--bevel-3),
      100% calc(100% - var(--bevel-1)),
      calc(100% - var(--bevel-1)) 100%,
      var(--bevel-3) 100%,
      var(--border-width) calc(100% - var(--bevel-2)),
      var(--border-width) var(--bevel-2)
    );
  }
  
  .button-inside {
    --padding-vertical: 3px;

    display: block;
    padding: var(--padding-vertical) 14px calc(var(--padding-vertical) - .05em);

    clip-path: polygon(
      var(--bevel) 0,
      calc(100% - var(--bevel)) 0,
      100% var(--bevel),
      100% calc(100% - var(--bevel)),
      calc(100% - var(--bevel)) 100%,
      var(--bevel) 100%,
      0 calc(100% - var(--bevel)),
      0 var(--bevel)
    );
    text-align: center;
  }
}

.add-btn {
    position: absolute;
    top: 20px;
    right: 30px;
    border-radius: 0 6px 0 6px;
    padding: 10px 15px;

    z-index: 20;
}

p.maxxed-out {
    margin-top: 30px;
    background: var(--color-green) !important;
    color: #fff;

    padding: 5px;
    border-radius: 5px;
}

p.maxxed-out a {
    color: #fff;
    text-decoration: underline;
}

.light-theme .Dashboard .example-btn {
    color: var(--color-black);
}

.dark-theme-color .Dashboard .example-btn {
    color: var(--color-black) !important;
}

.Dashboard .dark-text {
    display: flex;
    justify-content: center;
    margin: 20px 0 0;
    gap: 5px;
    font-size: 12px;
    align-items: center;
}

.Dashboard .your-url {
    font-size: 20px;
    text-align: center;
}

.Dashboard .tap-public-url {
    transition: background-color .4s ease, color .4s ease;
    text-align: center;
    background-color: rgba(255, 255, 255, 1);
    /* position: sticky;
    top: 100px; */
    z-index: 100;

    display: flex;
    justify-content: center;
    gap: 10px;
    padding: 3px 30px;
}

.Dashboard .tap-public-url i {
    background-color: var(--color-green);
    padding: 5px;
    font-size: 20px;
    color: #fff;
    min-width: 35px;
    border-radius: 10px;
    transition: color .4s ease, font-size .4s ease;
    cursor: pointer;
}

.Dashboard .tap-public-url a {
    color: var(--color-deep-blue);
    font-size: 20px;
    transition: color .4s ease, font-size .4s ease;
}

.Dashboard .tap-public-url.fixed {
    background-color: rgba(255, 255, 255, .666);
}

.Dashboard .tap-public-url.fixed a,
.Dashboard .tap-public-url.fixed i {
    font-size: 16px;
    /* color: #000; */
}


@media (max-width: 800px) {
    .Dashboard {
        overflow: hidden;
    }
    .Dashboard .ProductCard {
        max-width: 95vw !important;
    }
}

.custom-color-picker {
    display: flex;
    justify-content: center;
    position: relative;
    padding: 0 30px;
}

.custom-color-picker>div {
    width: 100% !important;
}

.Dashboard .close-button {
    background-color: #dc3545;
    color: #fff;
    border: none;
    border-radius: 5px;
    
    right: 0;
    position: absolute;
    bottom: -10px;
    transform: translateY(100%);
}
