.account-page {
    padding: 0 20px;
}

.account-page .btn {
    background: var(--color-deep-blue);
    border-color: var(--color-deep-blue);
}

.account-body .footer {
    display: none;
}

.account-page .extras {
    margin-top: 1em;
}

.account-app .intro {
    max-width: 380px;
    margin: 10px auto;
}

.account-app .threads {
    width: 17px;
    height: 17px;
}

.account-app #socials {
    visibility: hidden;
}