nav {
  align-items: center;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000 !important;
}

nav .nav-meta {
  display: flex;
  justify-content: space-between;
  padding: 10px 15px;
  box-sizing: border-box;

  margin: 18px;
  background-color: rgb(152 183 178 / 52%);

  backdrop-filter: blur(10px);
  border-radius: 50px;
  border-width: 1px;
  border-color: rgb(255 255 255 / 9%);
  border-style: solid;
}

nav .logo-meta {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 15px;
  width: 100%;
}

.logged-in nav .logo-meta img {
  filter: invert(90%);
}

.logged-in .logo-meta {
  width: auto;
}

nav img {
  max-width: 150px;
  cursor: pointer;
  display: block;
}

nav .anticon {
  font-size: 32px;
}

nav a {
  text-decoration: none;
  color: #333;
  margin-right: auto;
}

nav .menu {
  list-style: none;
  display: flex;
  align-items: center;
  margin: 0;
  margin-left: auto;
  padding: 0;
}

.sign-up-free {
  background-color: #fff;
  color: #000 !important;
}

.profile-nav {
  position: relative;
  top: 3px;
  right: 3px;
}

.middle-items {
  display: none !important;
}

@media (max-width: 768px) {
  nav .menu {
    margin: 0 auto;
  }
  .middle-items {
    display: flex !important;
  }
  nav .auth-icon .menu {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 65%;
    justify-content: center;
  }
  nav .logo-meta {
    width: auto;
  }
  nav .logo-meta .menu {
    opacity: 0;
    pointer-events: none;
  }
}

nav .menu a {
  color: #fff;
  font-size: 20px;
  padding: 10px 15px;
  border-radius: 50px;
  white-space: nowrap;
}

.home-body nav .menu a {
  color: #fff;
}

a.sign {
  background-color: #fff;
  color: #000 !important;
  font-size: 18px !important;
}

nav .menu a:hover {
  color: var(--color-yellow);
}

nav .account-icon {
  font-size: 22px;
}

nav .ant-dropdown-trigger {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 991px) {
  nav .menu a {
    font-size: 16px;
    padding: 12px 15px;
    margin: 0 7px;
  }

  nav img {
    max-width: 100px;
  }
}

@media (max-width: 320px) {
  nav .menu a {
    font-size: 14px;
    /* padding: 12px 15px; */
    margin: 0;
  }
}
