.hamburger {
    width: 30px;
    height: 22px;
    position: absolute;
    cursor: pointer;
    right: 35px;
    z-index: 90;
    top: 50%;
    transform: translateY(-50%);
  }

  @media(min-width:768px) {
    .hamburger {
        opacity: 0;
        pointer-events: none;
    }
  }
  
  .hamburger span {
    display: block;
    position: absolute;
    width: 100%;
    height: 3px;
    background-color: white;
    border-radius: 50px;

    transition: transform 0.4s var(--easing-authentic-motion), opacity 0.4s var(--easing-authentic-motion), top 0.4s var(--easing-authentic-motion), background-color 0.4s var(--easing-authentic-motion);
  }
  
  .hamburger span:first-child {
    top: 0;
  }
  
  .hamburger span:nth-child(2) {
    top: 50%;
    transform: translateY(-50%);
  }
  
  .hamburger span:last-child {
    bottom: 0;
    top: auto;
  }
  
  /* When toggled */
  .hamburger.toggled span {
    background-color: var(--color-green);
  }
  

  .hamburger.toggled span:first-child {
    transform: translateY(10px) rotate(45deg);
  }
  
  .hamburger.toggled span:nth-child(2) {
    opacity: 0;
    transform: translateY(-50%) scale(0);
  }
  
  .hamburger.toggled span:last-child {
    top: 10px;
    transform: rotate(-45deg);
  }
  
