.highlights {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 25px 200px;
  background: var(--color-green);
  /* Add more styles for layout and sizing */

  .screen {
    position: relative;
    flex: 1.25;
  }

  .main-image {
    width: 100%;
    height: auto;
  }

  .right-side {
    display: flex;
    flex-direction: column;
    flex: 1;
    max-width: 400px;

    h2 {
      margin: 0;
      text-align: left;
      color: var(--color-bright-blue);
      font-weight: 600;
      font-size: 70px;
      line-height: 1;
    }

    p {
      font-size: 22px;
      color: #fff;
      font-weight: 600;
      line-height: 1.2;
      margin-top: 15px;
      max-width: 300px;
    }
  }

  @media (max-width: 1400px) {
    padding: 25px 100px;

    .right-side {
      h4 {
        font-size: 50px;
      }

      p {
        margin-top: 10px;
      }
    }
  }

  @media (max-width: 1200px) {
    .right-side {
      h4 {
        font-size: 40px;
      }
      p {
        font-size: 20px;
      }
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 0;

    .right-side {
      align-items: center;
      text-align: center;

      h4 {
        font-size: 50px;
      }
      p {
        margin: 30px 0 40px;
        max-width: 300px;
      }
    }
  }
}
